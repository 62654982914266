<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    TheNavBar: () => import('./components/TheNavBar.vue'),
    OutramSpinner: () => import('./components/OutramSpinner.vue'),
    NotificationComponent: () => import('./components/NotificationComponent.vue'),
    ServiceUnavailablePage: () => import('./views/ServiceUnavailablePage.vue'),
    TheSystemMessage: () => import('./components/TheSystemMessage.vue'),
  },
  computed: {
    ...mapGetters({
      newFrontend: 'ui/newFrontendAvailable',
      status: 'ui/status',
      offline: 'ui/offline',
      loading: 'ui/loading',
      loadingMessage: 'ui/loadingMessage',
    }),
    showStatusMessageClose() {
      if (this.offline) return false;
      if (!this.$route.matched.some(route => route.meta.navbar)) return false;
      return true;
    },
  },
  mounted() {
    // Define global responses to Axios Errors
    this.$http.interceptors.response.use(resp => resp, (error) => {
      if (typeof error.response === 'undefined') {
        // Generic network error
        this.$bvToast.toast('Unable to Connect to PMGateway', {
          title: 'Network Error',
          transparency: false,
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else if (error.response.status === 401) {
        // If this response was for the login url, pass the error on
        if (error.response.config.url === '/user/cookie') {
          throw error;
        }

        // Handle 401 errors by logging out
        this.$store.dispatch('user/logout')
          .then(() => this.$router.replace({
            name: 'LoginPage',
            params: {
              session_expired: true,
              from: this.$route.path,
            },
          }));
      } else if (error.response.status === 503) {
        // A 503 error indicates that we've entered maintenance mode
        // Ensure the status is up to date
        this.$store.dispatch('ui/getStatus');
      } else if (error.response.status !== 403
                 && error.response.status !== 404
                 && error.response.status !== 409
                 && error.response.status !== 422) {
        // 403, 404, and 422 are user initiated so handled at the local component
        // Handle every other error globally with a message since it's our fault
        this.$bvToast.toast(`The server responded with a ${error.response.status} error code`, {
          title: 'An Error Occurred',
          transparency: false,
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
        // TODO Maybe post this error to the server log?
      }
      throw error;
    });
    this.$store.dispatch('ui/getStatus');
  },
};
</script>

<template>
  <div id="app">

    <!-- Navbar at the Top -->
    <the-nav-bar />
    <the-system-message v-bind:show-close='showStatusMessageClose' />

    <!-- Conditional Loading Overlay -->
    <b-overlay v-bind:show='this.loading' no-wrap fixed>
      <template v-slot:overlay>
        <outram-spinner/>
        <h3 class='d-block text-center'>{{ loadingMessage }}</h3>
      </template>
    </b-overlay>

    <!-- Main Content  -->
    <router-view v-if='!offline' />
    <service-unavailable-page v-else />

    <!-- Shows a Progress Bar when this.$Progress is used -->
    <vue-progress-bar></vue-progress-bar>

    <!-- Alert for when a new Frontend is available -->
    <b-alert v-bind:show='newFrontend'
             class='position-fixed fixed-bottom m-0 rounded-0'
             style='z-index: 2000;'
             variant='warning'>
      <b-container>
        <b-row align-h='center'>
          <b-col cols='auto'>
            <p class='text-center lead'>
              A new version of this page is available. Please refresh.
            </p>
          </b-col>
        </b-row>
        <b-row align-h='center'>
          <b-col cols='auto'>
            <b-btn variant='primary'
                   class='text-center'
                   @click='$window.location.reload()'>
              Refresh
            </b-btn>
          </b-col>
        </b-row>
      </b-container>
    </b-alert>

    <!-- Notifications Modal -->
    <b-modal id='notification-modal'
             title='Notifications'
             size='sm'
             hide-footer>
      <notification-component/>
    </b-modal>

  </div>
</template>

<style lang="scss">
@import './style/bs-theme.scss';
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index.scss";

html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>
