import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faList,
  faMapMarked,
  faTh,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faBell,
  faCircle,
  faWrench,
  faSearch,
  faPlus,
  faUndo,
  faCloudUploadAlt,
  faDownload,
  faSync,
  faCommentDots,
  faQuestionCircle,
  faCopy,
  faArrowRight,
  faCog,
  faUserCog,
  faToolbox,
  faTrash,
  faPencilAlt,
  faEdit,
  faMinusCircle,
  faCheck,
  faBan,
  faEllipsisH,
  faChevronCircleUp,
  faExchangeAlt,
  faPlay,
  faSignInAlt,
  faCloudDownloadAlt,
  faTimes,
  faCogs,
  faMicrochip,
  faArrowAltCircleRight,
  faDatabase,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(faList);
library.add(faMapMarked);
library.add(faTh);
library.add(faCheckCircle);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faBell);
library.add(faCircle);
library.add(faWrench);
library.add(faSearch);
library.add(faPlus);
library.add(faUndo);
library.add(faCloudUploadAlt);
library.add(faDownload);
library.add(faSync);
library.add(faCommentDots);
library.add(faQuestionCircle);
library.add(faCopy);
library.add(faArrowRight);
library.add(faCog);
library.add(faUserCog);
library.add(faToolbox);
library.add(faTrash);
library.add(faPencilAlt);
library.add(faEdit);
library.add(faMinusCircle);
library.add(faCheck);
library.add(faBan);
library.add(faEllipsisH);
library.add(faChevronCircleUp);
library.add(faExchangeAlt);
library.add(faPlay);
library.add(faSignInAlt);
library.add(faCloudDownloadAlt);
library.add(faTimes);
library.add(faCogs);
library.add(faMicrochip);
library.add(faArrowAltCircleRight);
library.add(faDatabase);
library.add(faInfoCircle);
