const store = {
  namespaced: true,
  state: {
    loading: false,
    loadingMessage: 'Loading...',
    status: {
      message: null,
      variant: null,
    },
    offlineStart: null,
    offlineEnd: null,
    currentLoggerView: 'Grid',
    newFrontendAvailable: false,
    onlineTimer: null,
  },
  mutations: {
    set_loading(state, { enabled, message }) {
      state.loading = enabled;
      state.loadingMessage = message || 'Loading...';
    },
    set_logger_view(state, payload) {
      state.currentLoggerView = payload;
    },
    new_frontend(state) {
      state.newFrontendAvailable = true;
    },
    set_status(state, payload) {
      state.status.message = payload.statusMessage;
      state.status.variant = payload.statusVariant;
      state.offlineStart = payload.offlineStart ? new Date(payload.offlineStart) : null;
      state.offlineEnd = payload.offlineEnd ? new Date(payload.offlineEnd) : null;
    },
    set_online_timer(state, timer) {
      state.onlineTimer = timer;
    },
  },
  actions: {
    set_logger_view({ commit }, view) {
      return new Promise((resolve, reject) => {
        const validViews = [
          'Grid',
          'List',
          'Map',
        ];
        // Check that the provided view is valid
        if (validViews.includes(view)) {
          commit('set_logger_view', view);
          return resolve();
        }
        return reject(new Error('UnknownLoggerView'));
      });
    },
    promptNewFrontend({ commit }) {
      return new Promise((resolve) => {
        commit('new_frontend');
        resolve();
      });
    },
    getStatus(context) {
      return new Promise((resolve, reject) => {
        // Read the status API to get any status messages
        this.$http({
          url: '/status',
          method: 'GET',
        })
          .then((resp) => {
            context.commit('set_status', resp.data);

            // If we are currently offline, set a timer for when the offline period ends
            if (context.getters.offline && context.state.offlineEnd) {
              const now = new Date();
              const timeout = context.state.offlineEnd - now;
              const timer = setTimeout(() => context.dispatch('getStatus'), timeout);
              context.commit('set_online_timer', timer);
            } else if (context.getters.offline && context.state.offlineEnd === null) {
              context.commit('set_online_timer', null);
            }

            // Resolve the promise
            resolve(resp.data);
          })
          .catch(err => reject(err));
      });
    },
    startLoading({ commit }, message) {
      return new Promise((resolve) => {
        commit('set_loading', { enabled: true, message: message || 'Loading...' });
        resolve();
      });
    },
    stopLoading({ commit }) {
      return new Promise((resolve) => {
        commit('set_loading', { enabled: false });
        resolve();
      });
    },
  },
  getters: {
    currentLoggerView: state => state.currentLoggerView,
    newFrontendAvailable: state => state.newFrontendAvailable,
    status: state => state.status,
    offlineStart: state => state.offlineStart,
    offlineEnd: state => state.offlineEnd,
    loading: state => state.loading,
    loadingMessage: state => state.loadingMessage,
    offline: (state) => {
      if (state.offlineStart) {
        const now = new Date();
        if (state.offlineEnd) {
          return now > state.offlineStart && now < state.offlineEnd;
        }
        return now > state.offlineStart;
      }
      return false;
    },
  },
};

export default store;
