import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enGB from '../lang/en-GB.json';
import enUS from '../lang/en-US.json';
import dateTimeFormats from '../lang/datetime.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'en-US',
  dateTimeFormats,
  messages: {
    'en-GB': enGB,
    'en-US': enUS,
  },
});
