import Vue from 'vue';

const store = {
  namespaced: true,
  state: {
    info: {
      totalUsers: null,
      totalLoggers: null,
      activeLoggers: null,
      pendingUsers: null,
    },
    pendingUsers: [],
  },
  mutations: {
    update_info(state, payload) {
      Vue.set(state.info, 'totalUsers', payload.userCount);
      Vue.set(state.info, 'totalLoggers', payload.loggerCount);
      Vue.set(state.info, 'activeLoggers', payload.activeLoggers);
      Vue.set(state.info, 'pendingUsers', payload.pendingUsers);
    },
    update_pending_users(state, payload) {
      Vue.set(state, 'pendingUsers', payload);
    },
  },
  actions: {
    update_info({ commit, rootGetters, dispatch }) {
      return new Promise((resolve, reject) => {
        if (rootGetters['user/accessLevel'] !== 'OEM') {
          resolve();
          return;
        }
        this.$http({
          method: 'GET',
          url: '/oem',
        })
          .then((resp) => {
            commit('update_info', resp.data);
            return dispatch('getPendingUsers')
              .then(() => resolve());
          })
          .catch(err => reject(err));
      });
    },
    getPendingUsers({ commit }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: '/oem/user/pending',
        })
          .then((resp) => {
            commit('update_pending_users', resp.data);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    approveUser(_, uid) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PATCH',
          url: `/oem/user/${uid}/accessLevel`,
          data: {
            accessLevel: 'USER',
          },
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    getUsers(_, { query, limit, offset }) {
      return this.$http({
        method: 'GET',
        url: '/oem/user',
        params: {
          q: query,
          n: limit,
          i: offset,
        },
      })
        .then(resp => resp.data);
    },
    getLoggers(_, { query, limit, offset }) {
      return this.$http({
        method: 'GET',
        url: '/oem/logger',
        params: {
          q: query,
          n: limit,
          i: offset,
        },
      })
        .then(resp => resp.data);
    },
    register(_, registerData) {
      return this.$http({
        method: 'POST',
        url: '/oem/user',
        data: {
          name: registerData.name,
          email: registerData.email,
          phone: registerData.phone ? registerData.phone.replace(/[^\d|^\+]/g, '') : null, // eslint-disable-line
          company: registerData.company,
          password: registerData.sendEmail ? undefined : registerData.password,
          accessLevel: registerData.accessLevel,
        },
      });
    },
  },
  getters: {
    totalUsers: state => state.info.totalUsers,
    totalLoggers: state => state.info.totalLoggers,
    activeLoggers: state => state.info.activeLoggers,
    pendingUserCount: state => state.info.pendingUsers,
    pendingUsers: state => state.pendingUsers,
  },
};

export default store;
