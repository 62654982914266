import Vue from 'vue';

const store = {
  namespaced: true,
  state: {
    auth: localStorage.getItem('user.id') ? 'reauth' : 'unauthenticated',
    id: localStorage.getItem('user.id') || null,
    name: null,
    company: null,
    email: null,
    phone: null,
    phone_verified: null,
    phone_verification_time: null,
    accessLevel: null,
    notifications: null,
    settings: null,
    user_subscription: null,
    logger_subscriptions: [],
  },
  mutations: {
    login_success(state, payload) {
      state.auth = 'authenticated';
      state.name = payload.name;
      state.company = payload.company;
      state.email = payload.email;
      state.phone = payload.phone;
      state.phone_verified = payload.phone_verified;
      Vue.set(state, 'accessLevel', payload.accessLevel);
      localStorage.setItem('user.id', payload.id);
    },
    reauth_fail(state) {
      state.auth = 'unauthenticated';
      localStorage.removeItem('user.id');
    },
    logout(state) {
      localStorage.removeItem('user.id');
      state.id = null;
      state.name = null;
      state.company = null;
      state.email = null;
      state.phone = null;
      state.phone_verified = null;
      state.accessLevel = null;
      state.notifications = null;
      state.settings = null;
      state.loggers = null;
      state.auth = 'unauthenticated';
    },
    set_notifications(state, notifications) {
      Vue.set(state, 'notifications', notifications);
    },
    update_user_subscription(state, userSub) {
      if (!userSub) {
        state.user_subscription = null;
      } else {
        state.user_subscription = {
          numLoggers: userSub.numLoggers,
          quotaPerLogger: userSub.quotaPerLogger,
          start: new Date(userSub.start),
          end: userSub.end ? new Date(userSub.end) : null,
        };
      }
    },
    update_logger_subscriptions(state, loggerSub) {
      const now = new Date().getTime();
      state.logger_subscriptions = loggerSub.map(sub => ({
        serial: sub.serial,
        quota: sub.quota,
        start: new Date(sub.start),
        end: sub.end ? new Date(sub.end) : null,
        status: (
          new Date(sub.start).getTime() < now
          && (new Date(sub.end).getTime() > now || !sub.end)
        ),
      }));
    },
    update_details(state, newDetails) {
      if ('company' in newDetails || newDetails.company) state.company = newDetails.company;
      if ('phone' in newDetails && newDetails.phone) {
        Vue.set(state, 'phone', newDetails.phone);
        Vue.set(state, 'phone_verified', false);
      }
    },
    phone_verification_sent(state) {
      Vue.set(state, 'phone_verification_time', new Date());
    },
    phone_verified(state) {
      state.phone_verified = true;
    },
  },
  actions: {
    /**
     * Authentication Actions
     */
    login({ commit, dispatch }, loginData) {
      if (loginData.rememberMe) {
        localStorage.setItem('email', loginData.email);
      } else {
        localStorage.removeItem('email');
      }
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/user/cookie',
          method: 'GET',
          auth: {
            username: loginData.email,
            password: loginData.password,
          },
          params: {
            remember: loginData.rememberMe ? 1 : undefined,
          },
        })
          .then((resp) => {
            commit('login_success', resp.data);
            dispatch('admin/update_info', null, { root: true });
            dispatch('fetchNotifications');
            resolve();
          })
          .catch((err) => {
            commit('reauth_fail');
            reject(err);
          });
      });
    },
    reauth({ commit, state, dispatch }) {
      return new Promise((resolve) => {
        if (state.auth === 'reauth') {
          // We should have an access token, try and refresh the state
          this.$http({
            url: '/user',
            method: 'GET',
          })
            .then((resp) => {
              commit('login_success', resp.data);
              dispatch('admin/update_info', null, { root: true });
              dispatch('fetchNotifications');
              resolve();
            })
            .catch(() => {
              commit('reauth_fail');
              resolve();
            });
        }
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        this.$http({
          url: '/user/cookie',
          method: 'DELETE',
        });
        commit('logout');
        resolve();
      });
    },
    fetchNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: '/user/notifications',
        })
          .then((resp) => {
            commit('set_notifications', resp.data);
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    dismissNotification({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'DELETE',
          url: `/user/notifications/${id}`,
        })
          .then(() => {
            dispatch('fetchNotifications');
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    /**
     * Authenticated Actions
     */
    fetchSubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        const userPromise = this.$http({
          url: '/user/user-subscription',
          method: 'GET',
        })
          .then((resp) => {
            if (resp.status === 204) {
              commit('update_user_subscription', null);
            } else {
              commit('update_user_subscription', resp.data);
            }
          });
        const loggerPromise = this.$http({
          url: '/user/logger-subscriptions',
          method: 'GET',
        })
          .then((resp) => {
            commit('update_logger_subscriptions', resp.data);
          });
        Promise.all([userPromise, loggerPromise])
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    updateDetails({ commit }, { company, phone }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PATCH',
          url: '/user',
          data: {
            company: company || undefined,
            phone: phone || undefined,
          },
        })
          .then(() => {
            commit('update_details', { company, phone });
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    changePassword(_, password) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PUT',
          url: '/user/passwd',
          data: { password },
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    sendPhoneVerification({ commit }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: '/user/phone_val',
        })
          .then(() => {
            commit('phone_verification_sent');
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    verifyPhone({ commit }, code) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PUT',
          url: '/user/phone_val',
          data: { code },
        })
          .then(() => {
            commit('phone_verified');
            resolve();
          })
          .catch(() => reject());
      });
    },
    /**
     * Registration and Unauthenticated Actions
     */
    register(_, userData) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/user',
          method: 'POST',
          data: userData,
        })
          .then(() => resolve())
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 409) {
                reject(new Error('UserAlreadyExists'));
              }
            }
          });
      });
    },
    validateEmail(_, token) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PUT',
          url: '/user/email_val',
          data: {
            token,
          },
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    forgotPassword(_, email) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: '/user/passwd_reset',
          params: {
            email,
          },
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    passwordReset(_, { password, token }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PUT',
          url: '/user/passwd_reset',
          data: {
            token,
            password,
          },
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
  },
  getters: {
    auth: state => state.auth,
    id: state => state.id,
    name: state => state.name,
    company: state => state.company,
    email: state => state.email,
    accessLevel: state => state.accessLevel,
    notifications: state => state.notifications,
    settings: state => state.notifications,
    timeUntilNextPhoneVal: state => Math.round(
      new Date().getTime() - state.phone_verification_time.getTime(),
    ),
  },
};

export default store;
