import Vue from 'vue';

const store = {
  namespaced: true,
  state: {
    list: {},
    data: {},
    files: {},
    meter: {},
    cmds: {},
  },
  mutations: {
    /**
     * Updates the list of loggers available to the user
     * Payload is an object of loggers, as returned by the API
     */
    update_logger_list(state, payload) {
      const list = payload;
      payload.forEach((elem, i) => {
        list[i].last_update = new Date(elem.last_update);
        if ((new Date() - list[i].last_update) > 60 * 1000 * 60) {
          list[i].status = 'OFFLINE';
        }
      });
      Vue.set(state, 'list', list);
    },
    /**
     * Update the status data for a logger
     * lid is the logger ID to update.
     * Data is the status data as returned by the API
     */
    update_logger_data(state, { lid, data }) {
      // Process the data to put in correct form
      const d = data;
      d.lid = undefined;
      d.last_seen = new Date(d.last_seen);
      Vue.set(state.data, lid, d);
    },
    /**
     * Update the file list for a logger
     * lid contains the logger ID to update
     * files is an array of files as returned by the API
     */
    update_logger_files(state, { lid, files }) {
      const f = files;
      files.forEach((file, i) => {
        f[i].created = new Date(file.created);
      });
      Vue.set(state.files, lid, f);
    },
    /**
     * Update the list of logger commands for a logger
     * lid contains the logger ID to update
     * cmds is an array of commands as returned by the API
     */
    update_logger_commands(state, { lid, cmds }) {
      Vue.set(state.cmds, lid, cmds);
    },
    /**
     * Update the meter readings for a logger
     * lid is the logger ID to update
     * meter is an array of meter readings, as returned by the API
     */
    update_logger_meter(state, { lid, meter }) {
      Vue.set(state.meter, lid, meter);
    },
  },
  actions: {
    /**
     * Use the API to asyncronously update the list of loggers
     * for the currently logged in user
     */
    list({ commit }) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/logger',
          method: 'GET',
        })
          .then((resp) => {
            commit('update_logger_list', resp.data);
            resolve();
          })
          .catch((err) => {
            if (err.response.status === 401) {
              commit('user/logout');
            }
            reject(err);
          });
      });
    },
    /**
     * Use the API to asyncronously update all of the data for a logger
     */
    get({ commit, dispatch }, lid) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: `/logger/${lid}`,
        })
          .then((resp) => {
            commit('update_logger_data', { lid, data: resp.data });
            Promise.all([
              dispatch('getMeterReadings', lid),
              dispatch('getFiles', lid),
              dispatch('getCommands', lid),
            ])
              .then(() => resolve());
          })
          .catch(err => reject(err));
      });
    },
    /**
     * Use the API to asyncronously update the logger file list
     */
    getFiles({ commit }, lid) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: `/logger/${lid}/files`,
        })
          .then((resp) => {
            commit('update_logger_files', { lid, files: resp.data });
            resolve();
          })
          .catch(err => reject(err));
      });
    },
    /**
     * Use the API to asyncronously update the logger command list
     */
    getCommands({ commit }, lid) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: `/logger/${lid}/cmd`,
        })
          .then((resp) => {
            commit('update_logger_commands', { lid, cmds: resp.data });
            resolve(resp.data);
          })
          .catch(err => reject(err));
      });
    },
    /**
     * Use the API to issue a new command to the logger
     */
    issueCommand({ dispatch }, { lid, command, params }) {
      return this.$http({
        url: `logger/${lid}/cmd`,
        method: 'POST',
        data: {
          command,
          params,
        },
      })
        .then(() => dispatch('getCommands', lid));
    },
    /**
     * Use the API to update the logger name asyncronously
     */
    setName({ dispatch }, { lid, name }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PATCH',
          url: `/logger/${lid}`,
          data: {
            name,
          },
        })
          .then(() => dispatch('list'))
          .then(() => dispatch('get', lid))
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    /**
     * Use the API to asyncronously update the logger description
     */
    setDescription({ dispatch }, { lid, description }) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'PATCH',
          url: `/logger/${lid}`,
          data: {
            description,
          },
        })
          .then(() => dispatch('list'))
          .then(() => dispatch('get', lid))
          .then(() => resolve())
          .catch(err => reject(err));
      });
    },
    /**
     * Use the API to asynconously update the logger's location
     */
    setManualLocation(_, { lid, enableOverride, location }) {
      return this.$http({
        method: 'PUT',
        url: `/logger/${lid}/settings/location`,
        data: {
          override: enableOverride,
          coordinates: enableOverride ? location : null,
        },
      });
    },
    /**
     * Use the API to asynconously delete a file from the server
     */
    deleteFile({ dispatch }, { lid, filename }) {
      return this.$http({
        method: 'DELETE',
        url: `/logger/${lid}/files/${encodeURIComponent(filename)}`,
      })
        .then(() => dispatch('getFiles', lid));
    },
    /**
     * Prompt the logger to download a file and/or load it for next session
     */
    patchFile({ dispatch }, {
      lid,
      filename,
      promptLoad,
      promptDownload,
    }) {
      return this.$http({
        method: 'PATCH',
        url: `/logger/${lid}/files/${encodeURIComponent(filename)}`,
        data: {
          promptLoad,
          promptDownload,
        },
      })
        .then(() => dispatch('getFiles', lid));
    },
    /**
     * Use the API to asynconously get meter readings for the logger
     */
    getMeterReadings({ commit }, lid) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: `/logger/${lid}/meter`,
        })
          .then((resp) => {
            commit('update_logger_meter', { lid, meter: resp.data });
            resolve(resp.data);
          })
          .catch(err => reject(err));
      });
    },
  },
  getters: {
    list: state => state.list,
    listLength: state => Object.keys(state.list).length,
    data: state => state.data,
    files: state => state.files,
    meter: state => state.meter,
    cmds: state => state.cmds,
  },
};

export default store;
