import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optional
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import VueProgressBar from 'vue-progressbar';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import i18n from './plugins/lang';
import './plugins/fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(VueTelInput);

Vue.component('fa-icon', FontAwesomeIcon);

// eslint-disable-next-line
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Setup progress bar options
const progressBarOptions = {
  color: '#0f98c9',
  autoFinish: false,
  thickness: '4px',
};
Vue.use(VueProgressBar, progressBarOptions);

// Setup Axios for API calls
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.responseType = 'json';
axios.defaults.withCredentials = true;
axios.defaults.headers['Accept-Version'] = process.env.VUE_APP_API_VERSION;
Vue.prototype.$http = axios;
store.$http = axios;

Vue.prototype.$window = window;

Vue.config.productionTip = true;

window.App = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
