import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'LoginPage',
      component: () => import('./views/LoginPage.vue'),
    },
    {
      path: '/email_val',
      name: 'ValidateEmailPage',
      component: () => import('./views/ValidateEmailPage.vue'),
    },
    {
      path: '/pass_reset',
      name: 'PasswordResetPage',
      component: () => import('./views/PasswordResetPage.vue'),
    },
    {
      path: '/loggers',
      name: 'LoggerSelectPage',
      component: () => import('./views/LoggerSelectPage.vue'),
      meta: {
        auth: 'USER',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
        ],
      },
    },
    {
      path: '/logger/:lid',
      name: 'LoggerPage',
      component: () => import('./views/LoggerPage.vue'),
      meta: {
        auth: 'USER',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Loading...', // This gets replaced after page load
            to: null,
          },
        ],
      },
    },
    {
      path: '/feedback',
      name: 'FeedbackPage',
      component: () => import('./views/FeedbackPage.vue'),
      meta: {
        auth: 'USER',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Feedback',
            to: '/feedback',
          },
        ],
      },
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: () => import('./views/HelpPage.vue'),
      meta: {
        auth: 'USER',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Help',
            to: '/help',
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'ProfilePage',
      component: () => import('./views/ProfilePage.vue'),
      meta: {
        auth: 'USER',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Profile',
            to: '/profile',
          },
        ],
      },
    },
    {
      path: '/oem',
      name: 'OEMPage',
      component: () => import('./views/OEMPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
        ],
      },
    },
    {
      path: '/oem/user',
      name: 'OEMUserPage',
      component: () => import('./views/OEMUserPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
          {
            text: 'User Settings',
            to: '/oem/user',
          },
        ],
      },
    },
    {
      path: '/oem/user/:uid',
      name: 'OEMUserEditPage',
      component: () => import('./views/OEMUserEditPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
          {
            text: 'User Settings',
            to: '/oem/user',
          },
          {
            text: 'Loading...',
            to: '#',
          },
        ],
      },
    },
    {
      path: '/oem/logger',
      name: 'OEMLoggerPage',
      component: () => import('./views/OEMLoggerPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
          {
            text: 'Logger Settings',
            to: '/oem/logger',
          },
        ],
      },
    },
    {
      path: '/oem/logger/:lid',
      name: 'OEMLoggerEditPage',
      component: () => import('./views/OEMLoggerEditPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
          {
            text: 'Logger Settings',
            to: '/oem/logger',
          },
          {
            text: 'Loading...',
            to: '#',
          },
        ],
      },
    },
    {
      path: '/oem/system',
      name: 'OEMSystemPage',
      component: () => import('./views/OEMSystemPage.vue'),
      meta: {
        auth: 'OEM',
        navbar: true,
        breadcrumbs: [
          {
            text: 'Loggers',
            to: '/loggers',
          },
          {
            text: 'Admin',
            to: '/oem',
          },
          {
            text: 'System Settings',
            to: '/oem/system',
          },
        ],
      },
    },
    {
      path: '*',
      name: 'NotFoundPage',
      component: () => import('./views/NotFoundPage.vue'),
    },
  ],
});

/**
 * Navigation Guard for Authenticated Pages
 */
router.beforeEach(async (to, from, next) => {
  // If page reloaded, try to reuse old token to prevent re-login
  if (store.getters['user/auth'] === 'reauth') {
    await store.dispatch('user/reauth');
  }

  // Redirect away from authenticated pages if not logged in
  if (!!to.meta.auth && store.getters['user/auth'] === 'unauthenticated') {
    next({
      name: 'LoginPage',
      replace: true,
      params: {
        not_logged_in: !localStorage.getItem('user.id'),
        session_expired: !!localStorage.getItem('user.id'),
        from: to.path,
      },
    });
    return;
  }

  // Redirect to logger view if logged in
  if (to.name === 'LoginPage' && store.getters['user/auth'] === 'authenticated') {
    next({
      name: 'LoggerSelectPage',
      replace: true,
    });
    return;
  }

  next();
});

export default router;
