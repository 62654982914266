import Vue from 'vue';
import Vuex from 'vuex';

import ui from './ui';
import user from './user';
import loggers from './loggers';
import admin from './admin';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ui,
    user,
    loggers,
    admin,
  },
});

export default store;
